export const CONFIG = {
    cognitoUserPoolId: 'us-west-2_hulGkZArz',
    userPoolWebClientId: '3oqa0mfsauskombgnjlrn4j2cn',
    cognitoIdentityPoolId: 'us-west-2:65eac0d6-d864-4afe-8ca1-dd96e4a0e718',
    awsRegion: 'us-west-2',
    s3Region: 'ap-southeast-2',

    baseApiUrl: 'https://www.eparconnectserver.com/prod-',
    apiStage: 'prod',

    s3HostUrl: 'https://s3-ap-southeast-2.amazonaws.com',
    chemicalFilesBucket: "prod-au-chemical-files",
    equipmentFilesBucket: "prod-au-connect-equipment-manuals",
    courseFilesBucket: "prod-au-connect-courses",
    orgPublicBucket: 'prod-au-connect-org-public-data',
    nationalMapBucket: 'prod-au-national-map-site-data',
    reportUploadBucket: 'prod-au-connect-report',
    ehsSlideBucket: 'prod-au-connect-ehs-slide-images',
    commonDocumentsBucket: 'prod-au-connect-common-docs',

    reportEndPoint: "https://prod-reporting.eparconnectserver.com",

    adminAPIEndPoint: "https://www.eparconnectserver.com/prod-admin-api/prod",

    connectWebUrl: "https://www.v2.eparconnect.com",
      // tinyMce free version intergrated. dev and qa api keys generated using custom email and prod api key generated using 'nipun@smbapps.com.au' email
    tinyMceApiKey : "ap7grgf0zumf3pwxsttq56zacjem0c8606hdo5veb6ckuaut",
    mapboxglAccessToken: 'pk.eyJ1IjoiYXNpdGhhOTciLCJhIjoiY2x3dDd6Z2h6MDB2djJqbzh6djFrbmpkbyJ9.e8hhdozq53qFs3C43fcteQ'
}
